

.letter-spacing-lg{
    letter-spacing: 0.21875rem;
}

.letter-spacing-xs{
    letter-spacing: .008rem;
}

.letter-spacing-sm{
    letter-spacing: 0.045rem;
}

.lh-md{
    line-height: $line-height-md;
}

.fs-sm{
    font-size: $font-size-sm;
}
.fw-500{
    font-weight: 500;
}


.text-secondary-subtle{
    color:$secondary-bg-subtle !important;
}