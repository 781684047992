//This works but doesn't allow for custom colors;
// .badge{
//     @each $color, $value in $theme-colors {
//         &.badge-#{$color} {
//             color: color-contrast($value);
//             background-color: RGBA(var(--#{$prefix}#{$color}-rgb), var(--#{$prefix}bg-opacity, 1));
//         }
//     }
// }
//badges in BS5 are problematic to customize so let's do this? 

.badge.badge-primary{
    color: $primary;
    background-color: RGBA(to-rgb($primary), 0.2);
}
.badge.badge-secondary{
    color: $secondary;
    background-color: RGBA(to-rgb($secondary), 0.2);
}
.badge.badge-success{
    color: $success;
    background-color: RGBA(to-rgb($success), 0.2);
}
.badge.badge-info{
    color: $info;
    background-color: RGBA(to-rgb($info), 0.2);
}
.badge.badge-warning{
    color: $warning;
    background-color: RGBA(to-rgb($warning), 0.2);
}
.badge.badge-danger{
    color: $danger;
    background-color: RGBA(to-rgb($danger), 0.2);
}
.badge.badge-light{
    color: #A5A5A5;
    background-color: #F5F7F7;//RGBA(to-rgb($light), 0.2);
}
.badge.badge-dark{
    color: $dark;
    background-color: RGBA(to-rgb($dark), 0.2);
}
