.section-bg-icon-primary {
    background: url(/assets/img/section-bg.svg) no-repeat $primary;
    background-size: cover;
}
.hero-bg{
    height: 100vh;
    background-position: center;
    background-size: cover;
}


.card {
    border: none;
    overflow: hidden;

    img {
        transition: all .6s;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }

    .card-img-overlay {
        background-color: rgba(#000, .3);
    }
}


.call-btn {
    &:hover {
        p {
            color: $purple-300;
        }

        svg circle {
            fill: $purple-300;
        }
    }
}

.bg-position-center{
    background-position: center;
}

.play-icon{
    max-width: 70%;
}


// responsive fonts
@media (max-width:768px) {
    .display-sm-4 {
        font-size: calc(1.375rem + 1.5vw);
    }

    .h2-sm {
        font-size: calc(1.2875rem + .45vw);
    }

    .h2-sm-custom {
        font-size: 1.625rem;
    }
    .hero-bg{
        height: 80vh;
    }
}