@import './variables';

//buttons & links
.btn-icon-left {
    display: inline-grid;
    grid-template-columns: 25px 1fr;
    grid-column-gap: 12px;
    align-items: center;
    transition: all .3s ease-in-out;

    img {
        width: 12px;
        transition: all .3s ease-in-out;
    }
}

.btn-icon-right {
    display: inline-grid;
    grid-template-columns: 1fr 12px;
    grid-column-gap: 12px;
    align-items: center;
    transition: all 0.2s ease;

    &:hover {
        grid-column-gap: 18px;
    }
}

.btn-primary {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$primary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #{$purple-300};
    --bs-btn-hover-border-color: #{$purple-300};
    --bs-btn-focus-shadow-rgb: 38, 38, 156;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$purple-300};
    --bs-btn-active-border-color: #{$purple-300};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$primary};
    --bs-btn-disabled-border-color: #{$primary};
}

.btn-secondary {
    --bs-btn-color: #{$primary};
    --bs-btn-bg: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #{$primary};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-focus-shadow-rgb: 38, 38, 156;
    --bs-btn-active-color: $primary;
    --bs-btn-active-bg: #{$primary};
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$secondary};
    --bs-btn-disabled-border-color: #{$secondary};
}

.btn-success {
    --bs-btn-color: #{$white};
    --bs-btn-bg: #{$secondary};
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: #{$white};
    --bs-btn-hover-border-color: #{$white};
    --bs-btn-focus-shadow-rgb: 38, 38, 156;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #{$white};
    --bs-btn-active-border-color: #{$white};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$secondary};
    --bs-btn-disabled-border-color: #{$secondary};
}

.btn-outline-secondary {
    --bs-btn-color: #{$primary};
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #{$secondary};
    --bs-btn-hover-color: #{$white};
    --bs-btn-hover-bg: #{$secondary};
    --bs-btn-hover-border-color: #{$secondary};
    --bs-btn-focus-shadow-rgb: 38, 38, 156;
    --bs-btn-active-color: $primary;
    --bs-btn-active-bg: #{$secondary};
    --bs-btn-active-border-color: #{$secondary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #{$secondary};
    --bs-btn-disabled-border-color: #{$secondary};
}

.btn-light {
    --bs-btn-color: #{$primary};
    --bs-btn-bg: #{$light};
    --bs-btn-border-color: #{$light};
    --bs-btn-hover-color: #{$light};
    --bs-btn-hover-bg: #{$primary};
    --bs-btn-hover-border-color: #{$primary};
    --bs-btn-focus-shadow-rgb: 213, 212, 211;
    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: #{$primary};
    --bs-btn-active-border-color: #{$primary};
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #{$primary};
    --bs-btn-disabled-bg: #{$light};
    --bs-btn-disabled-border-color: #{$light};
}

// This is gross but bootstrap is using important so can't modify there stuff. 
.primary-link {
    color: $primary;
    text-decoration-color: $primary;
    fill: $secondary;
    stroke: $secondary;

    &:hover,
    &:focus {
        color: $secondary;
        text-decoration-color: $secondary;
        fill: $secondary;
        stroke: $secondary;
    }
}
.light-link {
    color: $white;
    text-decoration-color: $white;
    fill: $white;
    stroke: $white;

    &:hover,
    &:focus {
        color: $secondary;
        text-decoration-color: $secondary;
        fill: $secondary;
        stroke: $secondary;
    }
}

.btn{
    &.btn-secondary{
        &:hover{
            color: $primary;
            background-color: $white;
            border-color: $white;
        }
    }
    &.btn-outline-secondary {
        &:hover {
            color: $primary;
            background-color: rgba($secondary, 0.3);
            border-color: $secondary;
            .logo-inverse-color{
                fill:$primary;
            }
        }

        &.dark-bg{
            &:hover {
                color: $primary;
                background-color: $secondary;
                border-color: $secondary;
                .logo-inverse-color{
                    fill:$primary;
                }
            }
        }
 
    }
    &.btn-hover-outline{
        &:hover {
            color: var(--bs-btn-color);
            background-color: transparent;
            border-color: var(--bs-btn-bg);
        }
    }
}