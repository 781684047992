@media (max-width:991px) {
.dropdown-menu{
        --bs-dropdown-bg:transparent;
        --bs-dropdown-border-width: 0px;
        --bs-dropdown-color:#fff;
        a,.service-title {
            color:#fff;
            font-size: 1.5rem;
            line-height: 2.85rem;
            font-weight: 400;
        }
    }
}
