// Font setup 
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

$font-family-base: "Mulish", sans-serif;
$headings-font-family: "Mulish", sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875; //14
$font-size-lg: $font-size-base * 1.252;
$enable-rfs: true;

$h1-font-size: 1.75rem; //28
$h2-font-size: 1.625rem; //26
$h3-font-size: 1.5rem; //24
$h4-font-size: 1.375rem; //22
$h5-font-size: 1.125rem; //18
$h6-font-size: 1rem; //16

$headings-margin-bottom: 0;
$headings-font-weight: 800;
$headings-line-height: 1.2;

//Headings 
$display-font-sizes: (
  1: 3.75rem,
  2: 2.85rem,
  3: 2.75rem,
  4: 2.5rem,
  //40
  5: 2.375rem,
  //38
  6: 2.125rem //34
);
$display-font-weight: 800;
$display-line-height: 1.2;


$line-height-base: 1.2;
$line-height-sm: 1.1 !default;
$line-height-md: 1.5;
$line-height-lg: 1.7;


$lead-font-size: 1.25rem;
$lead-font-weight: normal;
$font-weight-bold: 700;

$paragraph-margin-bottom: 0;



// .fs-3{
//     font-size: 0.9375rem; //15
// }
// .fs-4{
//     font-size: 0.8125rem //13
// }
// .fs-5{
//     font-size: 0.75rem; // 12px
// }
$font-sizes: (
  1: 2rem,
  2: 1rem,
  3: 0.9375rem,
  //15
  4: 0.8125rem,
  //13
  5: 0.75rem,
  //12
  6: 0.5rem,
  //89F83BC
);


//#region colors 
// Colors
$primary: #232259;
$secondary: #C9A9FF;
$light: #FAF9F8;
$danger: #EB0000;
$dark: #191841;
$white: #FFFFFF;
$gray: #344054;
$light-gray: #D0D5DD;
$purple-300: #9F83BC;
$success: #80C342;
$info: #009CDE;
$warning: #FFCF01;

$secondary-bg-subtle: #EFE5FF;
$secondary-text-emphasis: #9674B9;
$primary-text-emphasis: #161E29;

// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// );







$body-color: $primary;
$table-color: $primary;

$enable-negative-margins: true;
$enable-rounded: true;
$border-radius: 1.5rem;
$card-border-color: transparent;

$headings-color: $primary;

// $h7-font-size: 0.75rem;//14


$table-cell-padding-x-sm: 0;

// scss-docs-start type-variables




// reboot
$hr-margin-y: 0;
$hr-border-color: $primary;
$hr-opacity: .20;


// scss-docs-start theme-bg-subtle-variables
// $primary-bg-subtle:       tint-color($primary, 80%);

// $success-bg-subtle:       tint-color($success, 80%) !default;
// $info-bg-subtle:          tint-color($info, 80%) !default;
// $warning-bg-subtle:       tint-color($warning, 80%) !default;
// $danger-bg-subtle:        tint-color($danger, 80%) !default;
$light-bg-subtle: #f5f5f5;
// $dark-bg-subtle:          $gray-400 !default;

// btns
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.88rem;
$btn-font-size: 0.938rem;
$btn-line-height: 1.125;
$btn-font-weight: 700;
$btn-border-radius: 3.1875rem;

$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1.5rem;
$btn-font-size-sm: 0.938rem;

// $btn-padding-y-lg:            $input-btn-padding-y-lg;
// $btn-padding-x-lg:            $input-btn-padding-x-lg;
// $btn-font-size-lg:            $input-btn-font-size-lg;


// color filters
// $secondary-filter: invert(91%) sepia(18%) saturate(1185%) hue-rotate(37deg) brightness(95%) contrast(91%);
// $primary-filter: invert(13%) sepia(8%) saturate(1051%) hue-rotate(182deg) brightness(100%) contrast(86%);
// $light-filter: invert(97%) sepia(3%) saturate(1%) hue-rotate(283deg) brightness(119%) contrast(88%);



$link-decoration: none;

//#region spacers 
$spacers: (
  0: 0,
  1: 1px,
  2: 0.125rem,
  //2px
  4: 0.25rem,
  //4px
  6: 0.375rem,
  //6px
  8: 0.5rem,
  //8px
  10: 0.625rem,
  //10px
  12: 0.75rem,
  //12px
  14: 0.875rem,
  //14px
  16: 1rem,
  //16px
  20: 1.25rem,
  //20px
  24: 1.5rem,
  //24px
  28: 1.75rem,
  //28px
  32: 2rem,
  //32px
  36: 2.25rem,
  //36px
  40: 2.5rem,
  //40px
  42: 2.63rem,
  44: 2.75rem,
  48: 3rem,
  51: 3.19rem,
  56: 3.5rem,
  60: 3.75rem,
  64: 4rem,
  70: 4.37rem,
  74: 4.625rem,
  80: 5rem,
  84: 5.25rem,
  90: 5.625rem,
  98: 6.12rem,
  100: 6.25rem,
  110: 6.88rem,
  112: 7rem,
  114: 7.12rem,
  122: 7.65rem,
  128: 8rem,
  130: 8.625rem,
  140: 8.75rem,
  144: 9rem,
  150: 9.55rem,
  160: 10rem,
  171: 10.725rem,
  176: 11rem,
  180: 11.55rem,
  192: 12rem,
  208: 13rem,
  220: 13.75rem,
  224: 14rem,
  240: 15rem,
  256: 16rem,
  288: 18rem,
  320: 20rem,
  384: 24rem,
  448: 28rem,
  512: 32rem,
  576: 36rem,
  640: 40rem
);

//Nav 
$nav-link-font-weight: 500;
$nav-link-color: $white;
// $nav-link-hover-color:             $secondary;
$navbar-dark-color:    $white;
$navbar-light-color:   $primary-text-emphasis;
// $navbar-dark-hover-color:           $secondary;
// $navbar-dark-active-color:          $secondary;
$navbar-padding-y: 0rem;
$nav-link-padding-y: 1.375rem;
$navbar-nav-link-padding-x:         0.94rem;

// $navbar-padding-x: 2.5rem;
// $nav-link-padding-x:                2.5rem;
// $nav-link-font-weight:              700;
// $navbar-dark-color:                 $white;
// // $navbar-dark-brand-color:        #fff;
// // $navbar-dark-active-color:       #fff;
// $dropdown-spacer:                   0;
$dropdown-font-size: 1rem;
// $navbar-brand-font-size:            $font-size-sm;
// $dropdown-color:                    $white;
// $dropdown-bg:                       $white;
// $dropdown-link-color:               $primary;
// $dropdown-link-hover-bg:            $white;
// $dropdown-link-hover-color:         rgba($primary, 0.6);
// $dropdown-link-active-color:        rgba($primary, 0.6);
$dropdown-link-active-bg: transparent;
// $dropdown-padding-y:                2.875rem;
// $dropdown-padding-x:                2.875rem;
$dropdown-padding-y: 0;
$dropdown-padding-x: 0;
// $navbar-toggler-padding-y:          0;
// $navbar-toggler-padding-x:          0;
$dropdown-min-width: 280px;
// $dropdown-border-width:             0;
// $dropdown-border-radius:            0;

// Offcanvas
$offcanvas-vertical-height: 100vh !default;
// $offcanvas-bg-color:                $primary;
// $offcanvas-color:                   $light;

// Close
// scss-docs-start close-variables
$btn-close-width: 1.45em !default;
// $btn-close-color:            $light;

//accordion
$accordion-padding-x: 0;
$accordion-padding-y: 1.5rem;
$accordion-button-active-bg: transparent;
// $accordion-button-active-color: $secondary;
// $accordion-icon-color: $primary;
$accordion-button-focus-box-shadow: none;
$accordion-icon-width: 1rem;
$accordion-button-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M1.91 0.5L0.499999 1.91L12.09 13.5L5.5 13.5L5.5 15.5L15.5 15.5L15.5 5.5L13.5 5.5L13.5 12.09L1.91 0.5Z" fill="%23232259"/></svg>');
//same icon
$accordion-button-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M1.91 0.5L0.499999 1.91L12.09 13.5L5.5 13.5L5.5 15.5L15.5 15.5L15.5 5.5L13.5 5.5L13.5 12.09L1.91 0.5Z" fill="%23232259"/></svg>');
$accordion-icon-transform: rotate(-90deg);

//Form 
$input-placeholder-color: $primary;
$input-bg: $white;
$input-border-width: 0;
$input-padding-y: 0.75rem;
$input-padding-x: 1.25rem;
$input-border-radius: 0.5rem;
$input-border-color: $light-gray;
$input-box-shadow: none;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-border-width: 1px;

$form-label-margin-bottom:              .38rem;
$form-label-font-size:                  0.875rem;
$form-label-font-weight:                500;
$form-label-color:                      $gray;

//breadcrumbs
$breadcrumb-divider-color: $secondary;
$breadcrumb-active-color: $secondary;


//badges
// @import "helpers/color-bg"; this utility isn't nice to work with.. and bootstraps color contrast makes it hard to manage
//so i'm adding some mixings to help in _badges.scss
$badge-border-radius: 0.25rem;



//nav tabs
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: $secondary;
$nav-link-font-size: 1.25rem;
$nav-tabs-border-color: #464BEB;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: transparent;