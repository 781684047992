.accordion-button {
    &:not(.collapsed) {
        box-shadow: none;

    }

    @media (max-width:991px) {
        &::after {
            display: none;
        }
    }
}