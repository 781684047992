.dropdown-toggle {
    &::after {
        content: '';
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid white;
        border-top: 0.12em solid white;
        border-left: 0;
        transform: rotate(136deg);

        @media (max-width:991px) {
            top: .775em;
        }
    }
    &.active{
        &::after{
            border-color: $secondary;
        }
    }
}

.navbar-nav {
    --bs-nav-link-font-size: 0.9375rem;

}

.navbar {
    background-color: $white;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    .logo-inverse-color{
        fill:$primary;
    }
    &.navbar-dark{
        background-color:$primary;
        .btn-outline-secondary{
            color:$white;
            &:hover {
                color: $primary;
                background-color: $secondary;
                border-color: $secondary;
                .logo-inverse-color{
                    fill:$primary;
                }
            }
        }
        .logo-inverse-color{
            fill:$white;
        }
    }

    .nav-link {
        line-height: 1.125rem;
        &.active {
            color: $purple-300;
        }
        @media (max-width:991px){
            --bs-nav-link-font-size:1.5rem;
            font-weight: 400;
            line-height: 1.875rem;
            --bs-nav-link-padding-y:0.75rem;
        }

    }

    @media (min-width:992px) {
        &.scrolled {
            background-color: $white;
            color: $primary;
            --bs-navbar-color: $primary;
            box-shadow: 0 10px 45px 0 rgba(0, 0, 0, 0.06);

            .navbar-brand {
                path[fill='white'] {
                    fill: $primary;
                }
            }

            .btn-outline-secondary {
                --bs-btn-color: $primary;
            }
        }
    }
}

.navbar-toggler {
    border:none;
	&:focus {
		box-shadow: none;
	}
}

// toggle icon
.navbar-toggler-icon {
    background-image: none!important;
    background-color: var(--bs-gray-800);
    height: 2px;
    width: 25px;
    margin: 10px 0;
    position: relative;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }
  
  .navbar-toggler-icon::before {
    display: block;
    background-color: var(--bs-gray-800);
    height: 2px;
    content: "";
    position: relative;
    top: -7px;
    transition: all 0.15s ease-out;/*taken down to hide quicker*/
    transform-origin: center;
  }
  
  .navbar-toggler-icon::after {
    display: block;
    background-color: var(--bs-gray-800);
    height: 2px;
    content: "";
    position: relative;
    top: 4px;
    transition: all 0.35s ease-out;
    transform-origin: center;
  }
  
  .navbar-dark .navbar-toggler-icon,
  .navbar-dark .navbar-toggler-icon::before,
  .navbar-dark .navbar-toggler-icon::after {
    background-color: var(--bs-gray-100);
  }
  
  .navbar-toggler:not(.collapsed)[aria-expanded="true"] .navbar-toggler-icon {
    transform: rotate(45deg);
  }
  
  .navbar-toggler:not(.collapsed)[aria-expanded="true"] .navbar-toggler-icon::before {
    opacity: 0;
  }
  
  .navbar-toggler:not(.collapsed)[aria-expanded="true"] .navbar-toggler-icon::after {
    transform: rotate(-90deg) translateX(7px);
  }

@media (max-width: 991px) {
    .navbar-brand{
        max-width: 125px;
    }

    .navbar-collapse{
        text-align: center;
    }

    // .navbar-expand-lg .navbar-collapse {
    //     position: fixed;
    //     width: 100%;
    //     left: 0px;
    //     top: 74px;
    //     bottom: 0px;
    //     text-align: center;
    //     color: $secondary;

    //     &.show {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         flex-direction: column;

    //     }


    //     .link {
    //         border: 1px solid $primary;
    //         margin-top: 4.5rem;
    //         padding: .62rem 3.88rem;
    //         border-radius: $border-radius;
    //         margin-bottom: 0.75rem;
    //         // font-size: $small-font-size;

    //         .tel-icon {
    //             fill: $secondary;
    //         }
    //     }

    // }

    // .navbar-nav {
    //     .dropdown-menu {
    //         margin-top: 0;
    //         box-shadow: none;
    //     }
    // }
}







// .fixed-top {
// 	&.scrolled {
// 		background-color: $primary;
// 	}
// }



// .navbar-nav,
// .nav {
// 	&.nav-dark-text {
// 		.nav-link {
// 			color: $primary;
// 		}
// 	}
// 	.nav-link {
// 		color: $light;

// 		transition: all .3s ease-in-out;
// 		text-transform: uppercase;

// 		&.active,
// 		&.show,
// 		&:hover {
// 			color: $secondary;
// 		}
// 	}





// }

// .navbar-nav {
// 	.nav-link {
// 		font-size: 2rem;
// 	}
// }



// .menu-nav {
// 	font-weight: 400;
// 	font-size: 18px;
// 	line-height: 50%;
// 	margin-right: 15px;
// 	position: absolute;
// 	right: 42px;
// 	top: 50%;
// 	color: $light;

// 	@media (max-width: 768px) {
// 		display: none;
// 	}
// }

// .outer-menu {
// 	position: relative;
// }

// .outer-menu .checkbox-toggle {
// 	position: relative;
// 	top: 0;
// 	right: 0px;
// 	z-index: 2;
// 	cursor: pointer;
// 	width: 60px;
// 	height: 60px;
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div {
// 	transform: rotate(135deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:before,
// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	top: 0;
// 	transform: rotate(90deg);
// }

// .outer-menu .checkbox-toggle:checked+.hamburger>div:after {
// 	opacity: 0;
// }

// .outer-menu .checkbox-toggle:checked~.menu {
// 	pointer-events: auto;
// 	visibility: visible;
// }

// .outer-menu .checkbox-toggle:checked~.menu>div {
// 	transform: scale(1.5);
// 	transition-duration: 0.75s;
// 	@media (max-width:768px) {
// 		transform: scale(1.2);
//     }
// }

// .outer-menu .checkbox-toggle:checked~.menu>div>div {
// 	opacity: 1;
// 	transition: opacity 0.4s ease 0.4s;
// }

// .outer-menu .checkbox-toggle:checked:hover+.hamburger>div {
// 	transform: rotate(225deg);
// }

// .outer-menu .hamburger {
// 	position: absolute;
// 	top: 50%;
// 	right: 0;
// 	z-index: 1;
// 	width: 26px;
// 	background: transparent;
// 	border-radius: 0 0.12em 0.12em 0;
// 	cursor: pointer;
// 	transition: box-shadow 0.4s ease;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger>div {
// 	position: relative;
// 	flex: none;
// 	width: 100%;
// 	height: 2px;
// 	background: $light;
// 	transition: all 0.4s ease;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .hamburger:hover .hamburger-icon {
// 	background: $light;
// }

// .outer-menu .hamburger>div.hamburger-icon.add-white,
// .outer-menu .hamburger>div.hamburger-icon.add-white:before,
// .outer-menu .hamburger>div.hamburger-icon.add-white:after {
// 	background: $light;
// }

// .outer-menu .hamburger>div:before,
// .outer-menu .hamburger>div:after {
// 	content: "";
// 	position: absolute;
// 	z-index: 1;
// 	top: -7px;
// 	left: 0;
// 	width: 100%;
// 	height: 2px;
// 	background: inherit;
// 	transition: all 0.4s ease;
// }

// .outer-menu .hamburger>div:after {
// 	top: 7px;
// }

// .outer-menu .menu {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	pointer-events: none;
// 	visibility: hidden;
// 	overflow: hidden;
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	outline: 1px solid transparent;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div {
// 	width: 200vw;
// 	height: 200vw;
// 	color: $light;
// 	background: $primary;
// 	border-radius: 50%;
// 	transition: all 0.4s ease;
// 	flex: none;
// 	transform: scale(0);
// 	-webkit-backface-visibility: hidden;
// 	backface-visibility: hidden;
// 	overflow: hidden;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div {
// 	text-align: center;
// 	max-width: 90vw;
// 	max-height: 100vh;
// 	opacity: 0;
// 	transition: opacity 0.4s ease;
// 	overflow-y: auto;
// 	flex: none;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	justify-content: center;
// }

// .outer-menu .menu>div>div>ul {
// 	list-style: none;
// 	padding: 0 1em;
// 	margin: 0;
// 	display: block;
// 	max-height: 100vh;
// 	overflow: hidden;

// }

// .outer-menu .menu>div>div>ul>li {
// 	padding: 0;
// 	margin: .5em;
// 	font-size: 30px;
// 	font-weight: 300;
// 	display: block;
// 	color: $white;
// }

// .outer-menu .menu>div>div>ul>li>a {
// 	position: relative;
// 	display: inline;
// 	cursor: pointer;
// 	transition: color 0.4s ease;
// }

// .outer-menu .menu>div>div>ul>li>a:hover:after {
// 	width: 100%;
// }