.tab-pane{
    &.slide-in-up{
        transform: translate3d(0,50%,0);
        opacity: 0;
        &.show{
            animation-duration: 500ms;
            -webkit-animation-delay: 50ms;
            animation-delay: 50ms;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            animation-name: slide-in-up;
        }
    }
    &.slide-in-down{
        transform: translate3d(0, -50%, 0);
        opacity: 0;
        &.show{
            animation-duration: 500ms;
            -webkit-animation-delay: 50ms;
            animation-delay: 50ms;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
            animation-name: slide-in-down;
        }
    }
}


.nav-pill-control{
	list-style: disc;
    margin-left: 1.25rem;
    .nav-link{
        &:hover{
            color:$gray;
        }
        &.active{
            
            color:$secondary;
            
        }
    }
}

.nav-tabs{
    --bs-nav-tabs-link-hover-border-color:transparent;
}